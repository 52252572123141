<script lang="ts" setup>
const error = useError();

const router = useRouter();
</script>

<template>
  <div
    v-if="error"
    class="flex min-h-svh flex-col"
  >
    <AppHeader />
    <main class="grow bg-white">
      <Container class="relative py-4 text-navy">
        <header class="relative grid grid-cols-12 gap-4">
          <div
            class="col-span-12 items-center pt-4 text-center lg:col-span-6 lg:col-start-4 lg:py-5"
          >
            <h1>{{ error.statusCode }}</h1>
            <div class="h2 my-4">
              <template v-if="error.statusCode === 404">
                We are sorry, the page you are looking for cannot be found.
              </template>
              <template v-else>
                Sorry, something seems to have gone wrong, please try again
                later.
              </template>
            </div>

            

            <p
              v-if="error.statusCode === 404"
              class="mb-5"
            >
              Go back or try searching our website for its new location.
            </p>

            <div class="flex items-center justify-center gap-3">
              <a
                class="flex items-center gap-2 text-white"
                @click.prevent="
                  () => {
                    clearError();

                    router.back();
                  }
                "
              >
                <NuxtIcon
                  name="filled/button-arrow--blue"
                  class="rotate-180 transition-transform hover:-translate-x-2"
                  filled
                />
                Go Back
              </a>
            </div>
          </div>
        </header>
      </Container>
    </main>
    <AppFooter />
  </div>
</template>
