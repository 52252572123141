<script lang="ts" setup>
import type { Asset, Entry } from "contentful";
import {
  isTypeArticle,
  isTypeDownload,
  // isTypePage,
  type TypeArticleSkeleton,
  type TypeDownloadSkeleton,
} from "~/types/contentful";
import { hasImage, hasTopicOrSubtopic } from "~/lib/article/type-guards.js";
import { formatDate } from "~/lib/date-time";

const props = defineProps<{
  item: Entry<
    TypeArticleSkeleton | TypeDownloadSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >;
  inTable: boolean;
}>();

const type = computed(() => {
  if (isTypeArticle(props.item)) {
    return props.item.fields.articleType?.fields.title;
  }

  if (isTypeDownload(props.item)) {
    return props.item.fields.downloadType?.fields.title;
  }

  return undefined;
});

const topicOrSubtopic = computed(() => {
  if (hasTopicOrSubtopic(props.item)) {
    return props.item.fields.topicOrSubtopic;
  }

  return undefined;
});

const date = computed(() => {
  if (isTypeArticle(props.item)) {
    return formatDate(new Date(props.item.fields.publishDate).toISOString());
  } else if (isTypeDownload(props.item)) {
    return formatDate(new Date(props.item.fields.date).toISOString());
  } else {
    return undefined;
  }
});

const itemUrl = (
  item: Entry<
    TypeArticleSkeleton | TypeDownloadSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >,
) => (isTypeArticle(item) ? getUrl(item) : "");

const itemImage = computed(() =>
  hasImage(props.item)
    ? props.item.fields.image
    : isTypeDownload(props.item) && props.item.fields.downloadType?.fields.image
      ? props.item.fields.downloadType.fields.image
      : "",
);

const fileSize = (file: Asset<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">) => {
  const bytes = file.fields.file?.details.size ?? 0;
  return bytes > 1000000
    ? String(Math.round(bytes / 100000) / 10) + " MB"
    : String(Math.round(bytes / 1000)) + " kB";
};
</script>

<template>
  <div
    class="hub-item group relative grid grid-cols-12 gap-4"
    :class="{ 'lg:gap-8': !inTable, 'gap-y-2': inTable }"
  >
    <div
      class="col-span-12"
      :class="{
        'order-3 flex items-center gap-3': inTable,
        'lg:col-span-2': !inTable,
      }"
    >
      <span
        v-if="inTable"
        class="text-sm"
      >US SIF</span>
      <div
        v-if="inTable"
        class="h-3/4 w-px border-r border-r-[#999]"
      />
      <UiDate
        class="lg:flex"
        :class="{ hidden: !inTable }"
        :date="date"
        hide-slash
      />
    </div>

    <div
      v-if="itemImage"
      class="col-span-12 md:col-span-3 lg:col-span-2"
    >
      <NuxtLink :to="itemUrl(item)">
        <NuxtImg
          v-if="itemImage && itemImage.fields.file"
          class="!h-auto rounded-xl lg:!object-contain"
          :class="{ '!object-cover max-lg:w-full': !inTable }"
          :src="itemImage.fields.file.url"
          :alt="itemImage.fields.title"
          :width="400"
          :height="284"
          fit="contain"
          loading="lazy"
        />
      </NuxtLink>
    </div>

    <div
      class="flex flex-col"
      :class="{
        'col-span-12 md:col-span-9 lg:col-span-6': !inTable,
        'col-span-9': inTable,
      }"
    >
      <div
        v-if="!inTable"
        class="mb-4 flex items-center"
      >
        <UiBadge
          v-if="type"
          bg-colour="#e8e8e8"
        >
          {{ type }}
        </UiBadge>
        <UiDate
          class="ms-4 flex lg:hidden"
          :date="date"
        />
      </div>

      <h4 class="mb-0">
        <NuxtLink
          :to="
            isTypeDownload(item)
              ? item.fields.file?.fields.file?.url
              : itemUrl(item)
          "
        >
          {{ item.fields.title }}
        </NuxtLink>
      </h4>

      <UiCardBottom
        v-if="isTypeArticle(item)"
        :type="type"
        :source="item.fields.source"
        :topic-or-subtopics="topicOrSubtopic"
      />
    </div>

    <div
      v-if="isTypeDownload(item)"
      class="col-span-3 flex items-center justify-end"
    >
      <div
        v-if="item.fields.file"
        class="bottom-6 max-lg:absolute max-lg:right-0 max-lg:order-2 max-lg:text-sm lg:mr-4"
      >
        {{ item.fields.fileType }}
        {{ fileSize(item.fields.file) }}
      </div>
      <NuxtLink
        :to="item.fields.file?.fields.file?.url"
        target="_blank"
        class="inline-flex size-10 items-center justify-center rounded-md bg-blue"
      >
        <NuxtIcon
          class="text-white"
          name="ussif:download"
        />
      </NuxtLink>
    </div>

    <div
      v-if="isTypeArticle(item)"
      class="btn-wrap btn-wrap--dark absolute bottom-10 right-10 hidden opacity-0 transition-opacity group-hover:opacity-100 lg:block"
    >
      <NuxtLink
        :to="itemUrl(item)"
        class="bg-blue"
      >
        Read more
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hub-item {
  &__image:deep(.header-info__image) {
    img {
      @apply m-auto max-h-[230px];
    }
  }
}
</style>
