<script lang="ts" setup>
import type { EntryCollection, EntrySys } from "contentful";
// import type { TypeButton } from "~/types/TypeButton";
// import type { TypeTag } from "~/types/TypeTag";
import {
  isTypeArticleType,
  isTypeStaff,
  isTypeSubtopic,
  isTypeTopic,
  isTypeDownloadType,
} from "~/types/contentful";
import type {
  TypeArticleSkeleton,
  TypeCardBlockArticle,
  // TypeTopicSkeleton,
  // TypeSubtopicSkeleton,
} from "~/types/contentful";

// Define props for the component
const props = defineProps<{
  fields: TypeCardBlockArticle<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">["fields"];
  sys: EntrySys;
}>();

const route = useRoute();

// Define reactive variables for filtering and searching
// const filterYears = ref<string[]>([]);
// const filterThemes = ref<string[]>([]);
// const filterProjects = ref<string[]>([]);
const input = ref("");
const currentPage = ref(Number(route.query[`page_${props.sys.id}`]) || 1);
const perPage = 18;
const container = ref<ComponentPublicInstance>();

// Compute filtered items based on selected filters
const filters = computed(() =>
  props.fields.filter?.filter(
    (item): item is NonNullable<typeof item> => !!item,
  ),
);

const hubItemsType = computed(() =>
  filters.value && filters.value[0]?.sys.contentType.sys.id == "downloadType"
    ? "downloads"
    : "articles",
);

// This code is used to filter data from the contentful api
const articleTypeIds = computed(() =>
  filters.value?.filter(isTypeArticleType).map((item) => item.sys.id),
);

const downloadTypeIds = computed(() =>
  filters.value?.filter(isTypeDownloadType).map((item) => item.sys.id),
);

const authorIds = computed(() =>
  filters.value?.filter(isTypeStaff).map((item) => item.sys.id),
);

const topicOrSubtopicIds = computed(() =>
  filters.value
    ?.filter((t) => isTypeTopic(t) || isTypeSubtopic(t))
    .map((item) => item.sys.id),
);

// const projectIds = computed(() =>
//   filters.value?.filter(isTypeProject).map((item) => item.sys.id),
// );

// const filters = reactive([
//   filterYears.value,
//   filterThemes.value,
//   filterProjects.value,
// ]);

// const filterYear = computed(() => {
//   return filterYears.value.join(",");
// });

// Fetch data from APIs
// const { data: allThemes }
//   = await useLazyFetch<
//     Entry<
//       TypeTopicSkeleton | TypeSubtopicSkeleton,
//       "WITHOUT_UNRESOLVABLE_LINKS",
//       "en-GB"
//     >[]
//   >("/api/themes");

// const { data: allTags } = await useLazyFetch<TypeTag[]>("/api/tags", {
//   query: { input: "year" },
// });

// const { data: allProjects }
//   = await useLazyFetch<
//     Entry<TypeProjectSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">[]
//   >("/api/projects");

const { data: filteredItems, status } = await useLazyFetch<
  EntryCollection<TypeArticleSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">
>("/api/" + hubItemsType.value, {
  query: {
    articleTypeIds,
    authorIds,
    input,
    topicOrSubtopicIds,
    downloadTypeIds,
    page: computed(() => Number(route.query[`page_${props.sys.id}`]) || 1),
    // year: filterYear,
    perPage: props.fields.setLimit ? 3 : perPage,
  },
  transform: useCircularProtect,
});

// Compute filtered articles based on selected filters
const articles = computed(() => {
  return props.fields.article?.length
    ? props.fields.article.filter(
      (article): article is NonNullable<typeof article> => !!article,
    )
    : filteredItems.value?.items;
});

// Compute pagination total based on whether articles are filtered or not
const paginationTotal = computed(() => {
  return props.fields.article
    ? props.fields.article.length
    : (filteredItems.value?.total ?? 0);
});

// Watch for changes in search input and reset current page to 1
watch(input, () => (currentPage.value = 1));

// Watch for changes in current page and scroll into view
watch(currentPage, async (page) => {
  container.value?.$el.scrollIntoView({
    behavior: "smooth",
    block: "start",
    inline: "nearest",
  });

  await navigateTo({
    query: { [`page_${props.sys.id}`]: page },
    hash: route.hash,
  });
});

// Compute filter panel blocks for displaying filter options
// const filterPanelBlocks = computed(() => [
//   allTags.value?.map((tag) => tag.name.replace("Year: ", "")).sort() ?? [],
//   (
//     allThemes.value
//       ?.map((theme) => theme.fields.title)
//       .filter((t): t is NonNullable<typeof t> => !!t) ?? []
//   ).sort(),
//   // Add project filters if content type is Report
//   props.fields.contentType === "Report" && allProjects.value
//     ? (
//         allProjects.value
//           .map((project) => project.fields.name)
//           .filter(Boolean) as string[]
//       ).sort()
//     : [],
// ]);

// Handle filter change event
// const onFilterChange = (checked: boolean, id: string, blockIndex: number) => {
//   if (checked) {
//     filters[blockIndex].push(id);
//   } else {
//     filters[blockIndex].splice(filterProjects.value.indexOf(id), 1);
//   }
// };
</script>

<template>
  <UiContainer v-if="!fields.setLimit || articles?.length">
    <div class="flex flex-wrap gap-y-0">
      <div
        v-if="fields.displaySectionTitle"
        class="w-full"
      >
        <h2>{{ fields.title }}</h2>
      </div>
      <div
        v-if="!fields.article?.length && !fields.setLimit"
        class="w-full lg:mb-8"
      >
        <div class="flex w-full items-center border-b-2 border-b-charcoal/10">
          <NuxtIcon
            class="text-3xl text-charcoal/60"
            name="ussif:search"
          />
          <input
            v-model="input"
            class="w-full border-0 p-4 text-2xl placeholder:font-primary focus:ring-0 lg:text-desktop-3xl"
            type="text"
            placeholder="Search for..."
          />
        </div>
        <!-- <UiFilterPanel
          :titles="['Year', 'Theme', 'Project']"
          :blocks="filterPanelBlocks"
          :as-radio="[0]"
          scroll-on-open
          @on-filter-change="onFilterChange"
        /> -->
      </div>
      <div
        class="grid w-full"
        :class="{
          'opacity-10': status === 'pending',
          'gap-5 md:grid-cols-2 xl:grid-cols-3': fields.layout === 'Grid',
        }"
      >
        <template v-if="fields.layout !== 'Grid'">
          <BlockHubItem
            v-for="item in fields.setLimit ? articles?.slice(0, 3) : articles"
            :key="item.sys.id"
            :item="item"
            :in-table="fields.layout === 'Table'"
            class="border-2 border-white border-b-charcoal/10 py-6"
            :class="{
              'transition-all hover:bg-cream/40 lg:px-10 lg:py-14 lg:hover:rounded-xl lg:hover:border-blue':
                fields.layout === 'List',
            }"
          />
        </template>

        <template v-else-if="fields.layout === 'Grid'">
          <BlockHubItemGrid
            v-for="itemGrid in fields.setLimit ? articles?.slice(0, 3) : articles"
            :key="itemGrid.sys.id"
            :item="itemGrid"
          />
        </template>
      </div>
      <div class="flex w-full justify-center">
        <!-- <NuxtLink
          v-if="fields.button"
          :to="fields.button?.url"
          :class="`btn btn-${fields.button?.type}`"
        >
          {{ fields.button?.title }}
        </NuxtLink> -->
      </div>
      <ClientOnly>
        <vue-awesome-paginate
          v-if="paginationTotal > 12 && !fields.setLimit"
          v-model="currentPage"
          :total-items="paginationTotal"
          :items-per-page="perPage"
          :max-pages-shown="4"
          class="pagination-container"
          type="link"
          :link-url="`?page_${sys.id}=[page]${route.hash}`"
        >
          <template #prev-button>
            prev
          </template>
          <template #next-button>
            next
          </template>
        </vue-awesome-paginate>
      </ClientOnly>
    </div>
  </UiContainer>
</template>

<style lang="scss" scoped>
.card-block--article {
  .search {
    :deep(svg) {
      width: 24px;
      height: 24px;
    }

    input {
      width: 100%;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
}
</style>
