<script setup lang="ts">
const contentConfig = useContentConfig();
</script>

<template>
  <UiContainer
    :no-padding="true"
    class="z-20 lg:bg-navy"
  >
    <NavigationMain
      v-if="contentConfig?.mainNavigation"
      v-bind="contentConfig?.mainNavigation"
    />
  </UiContainer>
</template>

<style lang="scss" scoped>
nav a {
  background-size: 0% 1px;

  &:hover,
  &:focus {
    background-size: 100% 1px;
  }
}
</style>
